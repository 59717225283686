import { useState, useEffect } from 'react';
import axios from "../api/axios";

import './Footer.scss';

const URL = 'footer';


export const Footer = ( ) => {
  const [ data, setData ] = useState( [] );

  const getData = async () => {
    try {
      const response = await axios.get( URL, { params: { populate: 'deep' } } );
      setData( response.data.data.attributes.footer );
    } catch ( err ) {
      console.error( err );
    }
  };
  
  useEffect( () => {
    getData(); 
  }, [] ); 

  return (
    <div className='footer-area'>  
      <div className='container'>
        <ul className='footer-menu'>
          {
             data?.menufooter?.map((item, index) => (
              <li key={index}>
                <a href={item.linkMenu}>{item.textMenu}</a>
              </li>
            ))
          }
        </ul>
        <div className='infoArea'>
          <div className='area-item'>
            <p>Viziteaza-ne</p>
             <span>{data?.adress}</span>
          </div>
          <div className='area-item'>
            <p>Telefon</p>
             <a href={`tel:${data?.phone}`}>{data?.phone}</a>
          </div>
          <div className='area-item'>
            <p>E-mail</p>
             <a href={`mailto:${data?.mail}`}>{data?.mail}</a>
          </div>
        </div>
        <div className='copyRights'>{data?.copyrights}</div>
      </div>
    </div>
  );
};

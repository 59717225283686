import React from 'react';

const Page404 = () => {
  
  return (
    <div className='noFound container'>
      <h1>Ne pare rau, dar nu am gasit pagina pe care o cautati.</h1>

      E posibil sa fi ajuns aici din cauza unui link gresit, o adresa URL incorecta sau poate pagina cautata a fost stearsa sau mutata.

      Va rugam sa verificati adresa URL si incercati din nou. Daca credeti ca acesta este un eroare din partea noastra, va rugam sa ne contactati pentru a ne informa despre problema intampinata.

      Intre timp, va putem indruma catre pagina principala sau catre alte sectiuni relevante ale site-ului nostru.

      Va multumim pentru intelegere si ne cerem scuze pentru orice neplaceri cauzate.
    </div>
  );
};

export default Page404;

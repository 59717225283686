import React, { useState, useEffect } from 'react';
import axios from "../api/axios";
import Hero from "../components/Hero";
import LegalUs from "../components/Legal";

const urlHomepage = 'legal';

const Legal = ( ) => {

  
  const [ data, setData ] = useState( [] );

  const getData = async () => {
    try {
      const response = await axios.get( urlHomepage, { params: { populate: 'deep' } } );
      setData( response.data.data );
    } catch ( err ) {
      console.error( err );
    }
  };

  useEffect( () => {
    getData();
  }, [] );

  return (
    <div className="boxaPage">
        <Hero 
            title={data?.attributes?.hero?.title}
            body={data?.attributes?.hero?.body}
            cta={data?.attributes?.hero?.cta}
            image={data?.attributes?.hero?.images?.data[0]}
            variation="boxa"
        />
        <LegalUs
            text={data?.attributes?.body}
        />
    </div>
  );
};

export default Legal;

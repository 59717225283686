import { useState } from "react";
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";

import "./Form.scss";

const Form = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    setValue,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm({
    mode: "onTouched",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [message, setMessage] = useState(false);

  // Please update the Access Key in the .env
  const apiKey =
    process.env.PUBLIC_ACCESS_KEY || "3a9a2165-8113-4035-bd28-0e44bfb8f93b";

  const { submit: onSubmit } = useWeb3Forms({
    access_key: apiKey,
    settings: {
      from_name: "I Depozit User",
      subject: "Mesaj nou de pe site-ul tau",
    },
    onSuccess: (msg, data) => {
      setIsSuccess(true);
      setMessage(msg);
      reset();
    },
    onError: (msg, data) => {
      setIsSuccess(false);
      setMessage(msg);
    },
  });

  return (
    <div className="formContainer">
      <div className="container">
        <div className="formHeaderArea">
          <h2>Solicitati o vizita!</h2>
          <p>
            Daca cautati depozitare sigura si securizata in Bucuresti,
            solicitati o vizita mai jos:
          </p>
        </div>
        <div className="formWrapper">
          <form onSubmit={handleSubmit(onSubmit)} className="my-10">
            <input
              type="checkbox"
              id=""
              className="hidden"
              style={{ display: "none" }}
              {...register("botcheck")}
            ></input>
            <input type="checkbox" name="botcheck" className="hidden" style={{display: 'none'}}></input>
            <div className='row-form'>
                <div className="cell-form">
                    <label>Nume</label>
                    <input
                        type="text"
                        placeholder="Nume"
                        autoComplete="false"
                        onInvalid={(F) =>
                            F.target.setCustomValidity("Te rog sa completezi corect!")
                        }
                        onInput={(F) => F.target.setCustomValidity("")}
                        required
                        {...register("nume", {
                        required: "Te rog sa completezi corect!",
                        maxLength: 80,
                        })}
                    />
                </div>
                <div className="cell-form">
                    <label>Prenume</label>
                    <input
                        type="text"
                        placeholder="Prenume"
                        autoComplete="false"
                        onInvalid={(F) =>
                            F.target.setCustomValidity("Te rog sa completezi corect!")
                        }
                        onInput={(F) => F.target.setCustomValidity("")}
                        required
                        {...register("prenume", {
                        required: "Te rog sa completezi corect!",
                        maxLength: 80,
                        })}
                    />
                </div>
            </div>
            <div className="row-form">
                <div className="cell-form">
                    <label>E-mail</label>
                    <input
                        id="email_address"
                        type="email"
                        placeholder="E-mail"
                        name="email"
                        autoComplete="false"
                        onInvalid={(F) =>
                            F.target.setCustomValidity("Te rog sa completezi corect!")
                        }
                        onInput={(F) => F.target.setCustomValidity("")}
                        required
                        {...register("e-mail", {
                        required: "Enter your email",
                        pattern: {
                            value: /^\S+@\S+$/i,
                            message: "Please enter a valid email",
                        },
                        })}
                    />
                </div>
                <div className="cell-form">
                    <label>Telefon</label>
                    <input
                        type="tel"
                        placeholder="Telefon"
                        autoComplete="false"
                        onInvalid={(F) =>
                            F.target.setCustomValidity("Te rog sa completezi corect!")
                        }
                        onInput={(F) => F.target.setCustomValidity("")}
                        required
                        {...register("telefon", {
                        required: "Te rog sa completezi corect!",
                        maxLength: 80,
                        })}
                    />
                </div>
            </div>
            <div className="row-form">
                <div className="cell-form">
                    <label>Sunt interesat de:</label>
                    <select 
                        name="tipBoxa"
                        onInvalid={(F) =>
                            F.target.setCustomValidity("Te rog sa completezi corect!")
                        }
                        onInput={(F) => F.target.setCustomValidity("")}
                        required
                        {...register("tipBoxa", {
                            required: "Te rog sa completezi corect!",
                            maxLength: 80,
                        })}
                    >
                        <option value="">Alege o boxa</option>
                        <option value="boxaMini">Boxa Mini</option>
                        <option value="boxaMedie">Boxa Medie</option>
                        <option value="boxaMare">Boxa Mare</option>
                        <option value="boxaLarge">Boxa Large</option>
                        <option value="boxaExtraLarge">Boxa Extra Large</option>
                    </select>
                </div>
                <div className="cell-form">
                    <label>As dori sa fiu contactat:</label>
                    <select 
                        name="suna"
                        onInvalid={(F) =>
                            F.target.setCustomValidity("Te rog sa completezi corect!")
                        }
                        onInput={(F) => F.target.setCustomValidity("")}
                        required
                        {...register("suna", {
                            required: "Te rog sa completezi corect!",
                            maxLength: 80,
                        })}
                    >
                        <option value="">Selecteaza</option>
                        <option value="dimineata">Dimineata</option>
                        <option value="pranz">Pranz</option>
                        <option value="seara">Seara</option>
                    </select>
                </div>
            </div>
            <div className="row-form">
            <button
              type="submit"
              className="form-submit"
            >
              {isSubmitting ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 40" stroke="#059ED6">
                <g fill="none" fillRule="evenodd">
                  <g transform="translate(2 2)" strokeWidth="4">
                    <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="1s"
                        repeatCount="indefinite"/>
                    </path>
                  </g>
                </g>
              </svg>
              ) : (
                "Trimite"
              )}
            </button>
            </div>
           
          </form>

          {isSubmitSuccessful && isSuccess && (
            <div className="message-green">
              {"Formularul a fost trimis cu succes!"}
            </div>
          )}
          {isSubmitSuccessful && !isSuccess && (
            <div className="message-red">
              {"Ne pare rau! A avut loc o eroare. Incercati mai tarziu!"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Form;

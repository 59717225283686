import React, { useState, useEffect } from 'react';
import axios from "../api/axios";

import './Login.scss';

const LoginForm = () => {

  const urlHomepage = 'auth/local';
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [expire, setExpire] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.honeypot.value) {
      // Handle the case when the honeypot field is filled (likely a bot)
      setError(true);
      return;
    }
    try {
      const response = await axios.post(urlHomepage, {
        identifier: email,
        password: password,
      });

      const authToken = response.data.jwt;
      localStorage.setItem('authToken', authToken);

      if (response.status === 200) {
        // Login successful
        const userId = response.data.user.id;
        const today = new Date();
        var givenDate = new Date(response.data.user.endDate);
        if (givenDate.getTime() > today.getTime()) {
          window.location.href = `/user/${userId}`;
        } else {
          setExpire(true);
          localStorage.removeItem('authToken');
        }
        // Redirect to the user page using the user ID
        // window.location.href = `/user/${userId}`;
      } else {
        // Handle login error
        console.log('Login failed');
      }
    } catch (error) {
      // Handle request error
      setError(true)
      console.log('Error occurred during login:', error);
    }
    setEmail('');
    setPassword('');
  };

  useEffect(() => {
    const clearAuthToken = () => {
      // Clear the authToken from local storage after 5 seconds
      setTimeout(() => {
        localStorage.removeItem('authToken');
      }, 86400000);
    };

    clearAuthToken();

    // Clean up the timeout if the component is unmounted
    return () => clearTimeout(clearAuthToken);
  }, []);

  return (
    <div className='container PageLogin'>
      <form onSubmit={handleSubmit}>
        <h1>Login</h1>
        <div className='login_row mail_login'>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            required
            placeholder='Tastati Adresa de e-mail'
          />
        </div>
        <div className='login_row pass_login'>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
            placeholder='Tastati parola'
          />
        </div>
        <button className="form-submit" type="submit">Login</button>
        <input type="text" id="honeypot" name="honeypot" style={{ display: 'none' }} />
        {error ? 
          <p className='error_login'><span>A aparut o eroare. </span>Te rugam sa ne contactezi la <a href="tel:0751996600" className='callAction_UserPage'>0751996600</a> sau completand formularul din sectiunea <a href='/contact'>Contact</a>!</p>
          : undefined
        }
        {
          expire ?
          <p className='error_login'><span>Contul dumneavoastră a fost dezactivat! </span>Te rugam sa ne contactezi la <a href="tel:0751996600" className='callAction_UserPage'>0751996600</a> sau completand formularul din sectiunea <a href='/contact'>Contact</a>!</p>
          : undefined
        }
      </form>
    </div>
  );
};

export default LoginForm;

import './Multiplecomponent.scss';

const Multiplecomponent = ( props ) => {

    return (
      <div className={`multipleComponent ${props.variation}`}>
        <div className='container'>
            <div className='body-header'>
                <h2> {props.title} </h2>
                <p> {props.body} </p>
            </div>
            <div className='items-container'>
            {
                props?.items?.map((item, index) => (
                    <div className='item-element' key={index}>
                        <img src={`${process.env.REACT_APP_BASE_URL}${item?.img?.data?.attributes?.url}`} alt="boxe depozitare de inchiriat" />
                        <p className='title-area'>{item.Text}</p>
                        <div className='body-area' dangerouslySetInnerHTML={{__html: item.body}} />
                        { props.variation && 
                            <a href={item.cta} className='callAction'>Detalii</a>
                        }
                    </div>
                ))
            }
            </div>
            {
                !props.variation ? <a href='/contact' className='callAction'>Cere oferta</a> : undefined
            }
            
        </div>
      </div>
    );
  };
  
  export default Multiplecomponent;
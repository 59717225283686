import React, { useState } from 'react';
import axios from "../api/axios";

const Call = () => {

  const [call, setCall] = useState(false)

  const makeCall = async () => {
    try {
      await axios.post('/calls');
      console.log('Call initiated successfully!');
      setCall(true)
      setTimeout(() => {
        setCall(false)
      }, "5000");
    } catch (error) {
      console.error('Error initiating call:', error);
    }
  };

  return (
    <button className='openGate' disabled={call} onClick={makeCall}>
      {
        !call ?
        'Deschide Poarta'
        :
        'Se Deschide...'
      }
    </button>
  );
};

export default Call;

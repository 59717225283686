import './Simple2column.scss';

const Simple2column = ( props ) => {

    return (
      <div className="Simple2column">
        <div className='container'>
          <h3> {props.text} </h3>
          <a href={props.url} className='callAction'>{props.cta}</a>
        </div>
      </div>
    );
  };
  
  export default Simple2column;
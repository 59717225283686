import React, { useState, useEffect, useCallback } from 'react';
import axios from "../api/axios";
import Hero from "../components/Hero";
import Boxadetail from "../components/Boxadetail";
import { useParams } from 'react-router-dom';

const urlHomepage = 'boxes';

const Boxa = ( ) => {

  
  const [ data, setData ] = useState( [] );

  const { slug } = useParams();

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get( `${urlHomepage}`, { params: {
        populate: 'deep',
        filters: {
          slug: {
            $eq: slug
          }
        }
      } } );
      setData(response.data.data[0]);
    } catch (err) {
      console.error(err);
    }
  }, [slug]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className="boxaPage">
        <Hero 
            title={data?.attributes?.herosmall?.title}
            body={data?.attributes?.herosmall?.body}
            cta={data?.attributes?.herosmall?.cta}
            image={data?.attributes?.herosmall?.images?.data[0]}
            variation="boxa"
        />
        <Boxadetail 
            title={data?.attributes?.title}
            desc={data?.attributes?.desc}
            image={data?.attributes?.image}
            items={data?.attributes?.detalii}
            gallery={data?.attributes?.images}
        />
    </div>
  );
};

export default Boxa;

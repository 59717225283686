import React, { useState, useEffect } from 'react';
import axios from "../api/axios";
import Faq2 from "../components/Faq";

const urlHomepage = 'faq';

const Faq = ( ) => {

  
  const [ data, setData ] = useState( [] );

  const getData = async () => {
    try {
      const response = await axios.get( urlHomepage, { params: { populate: 'deep' } } );
      setData( response.data.data );
    } catch ( err ) {
      console.error( err );
    }
  };

  useEffect( () => {
    getData();
  }, [] );

  return (
    <div className="faq">
      <Faq2 
        title={data?.attributes?.title}
        body={data?.attributes?.body}
        items={data?.attributes?.faq}
      />
    </div>
  );
};

export default Faq;

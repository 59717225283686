import React, { useState, useEffect, useCallback } from 'react';
import axios from "../api/axios";
import './Article.scss';
import { useParams } from 'react-router-dom';

const urlHomepage = 'articles';

const Article = () => {

  const [data, setData] = useState([]);
  const { slug } = useParams();

  const getData = async () => {
    try {
      const response = await axios.get( `${urlHomepage}`, { params: {
        populate: 'deep',
        filters: {
          slug: {
            $eq: slug
          }
        }
      } } );
      setData( response.data.data[0] );
    } catch ( err ) {
      console.error( err );
    }
  };

  useEffect( () => {
    getData();
  }, [] );

  const timestamp = data?.attributes?.createdAt;
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString("ro-RO", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return (
    <div className="SingleArticle">
      <div className='container'>
        <p className="date-toShow">{formattedDate}</p>
        <h1>{data?.attributes?.title}</h1>
        <img src={`${process.env.REACT_APP_BASE_URL}${data?.attributes?.image?.data?.attributes?.url}`} alt="Depozit de inchiriat" />
        <div className='article_body' dangerouslySetInnerHTML={{ __html: data?.attributes?.description }} />
      </div>
    </div>
  );
};

export default Article;

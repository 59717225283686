import { React } from "react";

import './Despre.scss';


const LegalUs = ( props ) => {

    return (
        <div className="despreNoi container">
            <div className='body-area' dangerouslySetInnerHTML={{__html: props.text}} />
        </div>
    );
  };
  
  export default LegalUs;
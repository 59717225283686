import { React } from "react";
import CookieConsent from "react-cookie-consent";

import './Despre.scss';


export const Cookie = ( props ) => {

    return (
        <CookieConsent
        location="bottom"
        buttonText="Sunt de acord!"
        cookieName="IDepozitCookie"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#ffffff", fontSize: "16px" }}
        expires={150}
        >
        Site-ul nostru utilizeaza cookie-uri pentru a-ti imbunatati experienta si pentru a-ti afisa continut relevant atunci cand navighezi pe web.{" "}
        </CookieConsent>
    );
  };
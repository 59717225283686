import { React } from "react";

import './Faq.scss';



const Faq = ( props ) => {

    return (
      <div className="faqWrapper">
        <div className="container">
          <div className="faqTitleArea">
            <h2>{props.title}</h2>
            <p>{props.body}</p>
          </div>
          <div className="faqQuestions">
            {
              props?.items?.map((item, index) => (
                <div className="faqBox">
                  <h3>
                    {item.title}
                  </h3>
                  <p>
                    {item.body}
                  </p>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  };
  
  export default Faq;
import './Static2column.scss';

const Static2column = ( props ) => {

    return (
      <div className="static2Column">
        <div className=''>
            {
                props?.items?.map((item, index) => (
                    <div className={`item-element ${item.left}`} key={index}>
                        <div className='container'>
                            <div className='left-side'>
                                <h2>
                                    {item.headerText}
                                </h2>
                                <p>{item.bodyText}</p>
                            </div>
                            <img src={`${process.env.REACT_APP_BASE_URL}${item?.image?.data?.attributes?.url}`} alt="boxe depozitare de inchiriat" />
                        </div>
                    </div>
                ))
            }
        </div>
      </div>
    );
  };
  
  export default Static2column;
import React from 'react';
import ResponsiveGallery from 'react-responsive-gallery';

import './Boxdetail.scss';


const Boxadetail = ( props ) => {
  if (!props.gallery || !props.gallery.data || !Array.isArray(props.gallery.data)) {
    return null; // or display a message or fallback content
  }

    return (
      <div className="boxaDetail">
        <div className="container">
            <div className="leftC">
                <img src={`${process.env.REACT_APP_BASE_URL}${props?.image?.data?.attributes?.url}`} alt="Spatiu depozitare de inchiriat" />
            </div>
            <div className="rightC">
                <div className="headerAreaC">
                    <h3>{props.title}</h3>
                    <p>{props.desc}</p>
                </div>
                <div className="itemsDimensions">
                {
                    props?.items?.map((item, index) => (
                        <div className="item" key={index}>
                            {
                              item?.icon?.data?.attributes?.url &&
                              <img src={`${process.env.REACT_APP_BASE_URL}${item?.icon?.data?.attributes?.url}`} alt="boxe depozitare de inchiriat" />
                            }
                            <div className="itemContent">
                                <h4>{item.title}</h4>
                                <p>{item.desc}</p>
                            </div>
                        </div>
                    ))
                }
                </div>
                <a href="/contact" className="callAction">Cere detalii!</a>
            </div>
        </div>
        <div className='galleryMyWrapper'>
          <ResponsiveGallery 
            useLightBox
            images={props?.gallery?.data.map((image, index) => ({
              src: process.env.REACT_APP_BASE_URL + image.attributes.url,
              alt: image.attributes.alt,
            
            }))}
          />
        </div>
      </div>
    );
  };
  
  export default Boxadetail;
import React, { useEffect, useState } from 'react';
import axios from "../api/axios";
import { useParams } from 'react-router-dom';
import './UserPage.scss';
import Call from './Call';

const UserPage = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // New loading state
  const urlHomepage = 'users';
  const { id } = useParams();

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    if (authToken) {
      const fetchUser = async () => {
        try {
          const response = await axios.get(`${urlHomepage}/${id}`, { 
            params: { populate: 'deep' },
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });

          const userData = response.data;

          if (userData.id === getUserIdFromToken(authToken)) {
            setUser(userData);
          } else {
            setTimeout(() => {
              window.location.href = '/login'
            }, 3000);
          }
        } catch (error) {
          console.log('Error occurred while fetching user:', error);
        } finally {
          setLoading(false); // Stop loading after fetching user data
        }
      };

      fetchUser();
    } else {
      setLoading(false); // Stop loading if no auth token
    }

    const clearAuthToken = () => {
      setTimeout(() => {
        localStorage.removeItem('authToken');
      }, 86400000);
    };

    clearAuthToken();

    return () => clearTimeout(clearAuthToken);
  }, [id]);

  const getUserIdFromToken = (authToken) => {
    const tokenParts = authToken.split('.');
    if (tokenParts.length === 3) {
      const payload = JSON.parse(atob(tokenParts[1]));
      return payload.id;
    }
    return null;
  };

  const timestamp = user?.endDate;
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString("ro-RO", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const givenDate = new Date(user?.endDate);
  const currentDate = new Date();
  const timeDiff = givenDate.getTime() - currentDate.getTime();
  const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  if ( daysLeft < 1) {
    setTimeout(() => {
      window.location.href = '/login'
    }, 3000);
  }

  if (loading) {
    return <h1>Loading...</h1>; // Show a loading message while fetching data
  }

  return (
    <div className='userPage_container'>
      <div className='container'>
        {user ? (
          <div className='userPage_detail_wrapper'>
            <div className='photo'></div>
            <div className='userPage_infos'>
              <h2>Bine ai venit, {user.username}!</h2>
              <div className='details'>
                <h3>Mai jos regasesti informatii despre contul tau:</h3>
                <p>Ai inchiriate urmatoarele: <span className='dinamic_info'>{user.tipProdus}</span></p>
                <p>
                  Abonamentul expira in data de : <span className='dinamic_info'>{formattedDate}</span>!
                </p>
                <p>
                  Mai ai <span className={`${daysLeft < 8 ? 'red' : 'normal'} dinamic_info`}>{daysLeft}</span> zile abonamentul valabil!
                </p>
                <Call />
                <div className='disclaimer'>
                  Pentru detalii si plati te rugam sa ne contactezi la <a href="tel:0751996600" className='callAction_UserPage'>0751996600</a> sau completand formularul din sectiunea <a href='/contact'>Contact</a>!
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h1>Accesul interzis. Vei fi redirectionat catre pagina de login!</h1>
        )}
      </div>
    </div>
  );
};

export default UserPage;

import React, { useState, useEffect } from 'react';
import axios from "../api/axios";
import Hero from "../components/Hero";
import Subhero from "../components/Subhero";
import Static2column from "../components/Static2column";
import Multiplecomponent from "../components/Multiplecomponent";
import Simple2column from "../components/Simple2column";
// import Virtual from "../components/Virtual";

const urlHomepage = 'homepage';

const HomePage = ( ) => {

  
  const [ data, setData ] = useState( [] );

  const getData = async () => {
    try {
      const response = await axios.get( urlHomepage, { params: { populate: 'deep' } } );
      setData( response.data.data );
    } catch ( err ) {
      console.error( err );
    }
  };

  useEffect( () => {
    getData();
  }, [] );

  return (
    <div className="homepage">
      <Hero 
        title={data?.attributes?.HeroArea?.title}
        body={data?.attributes?.HeroArea?.body}
        image={data?.attributes?.HeroArea?.images?.data[0]}
        cta={data?.attributes?.HeroArea?.cta}
        variation={false}
      />
      <Subhero 
        items={data?.attributes?.subHeroArea}
      />
      <Static2column 
        items={data?.attributes?.Static2Column}
      />
      <Multiplecomponent 
        title={data?.attributes?.multipleComponent?.Title}
        body={data?.attributes?.multipleComponent?.bodytext}
        items={data?.attributes?.multipleComponent?.items}
      />
      <Simple2column 
        text={data?.attributes?.simple2column?.textAnnounce}
        url={data?.attributes?.simple2column?.ctaLink}
        cta={data?.attributes?.simple2column?.ctaText}
      />
      <Multiplecomponent 
        title={data?.attributes?.multiplecomponent2?.Title}
        body={data?.attributes?.multiplecomponent2?.bodytext}
        items={data?.attributes?.multiplecomponent2?.items}
        variation="storage"
      />
      {/* <Virtual /> */}
    </div>
  );
};

export default HomePage;

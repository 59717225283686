import { useState, useEffect, useRef } from 'react';
import axios from "../api/axios";

import './Header.scss';

const URL = 'header';


export const Header = ( ) => {
  const [ openMenu, setOpenMenu ] = useState( false );
  const [ data, setData ] = useState( [] );
  const [scroll, setScroll] = useState(false);
  const [ sub, setSub ] = useState( false );

  const [height, setHeight] = useState(0)
  const ref = useRef(null)

  const getData = async () => {
    try {
      const response = await axios.get( URL, { params: { populate: 'deep' } } );
      setData( response.data.data );
    } catch ( err ) {
      console.error( err );
    }
  };

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };
  const handleClick2 = () => {
    setSub(!sub);
  };
  
  useEffect( () => {
    getData(); 
    setHeight(ref.current.clientHeight)
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > height);
    });
  }, [height] );


  return (
    <div className={`header-navigation ${scroll ? "fixed" : undefined}`}>
      <div className='topBanner' ref={ref}>
        <a href='/contact'>Spatiu limitat disponibil. Asigurati-va depozitarea acum &raquo;</a>
      </div> 
      <div className='menubigContainer'>
        <div className='container'>
          <div className='header-wrapper'>
            <a href='/'><img className='logo' src={`${process.env.REACT_APP_BASE_URL}${data?.attributes?.logo_image?.data.attributes.url}`} alt="spatii mici de inchiriat"/></a>
            <ul className={`menu-wrapper ${openMenu ? "active" : undefined}`}>
              {
                data?.attributes?.Menu?.map( ( item, index ) => (
                  <li key={index} onClick={handleClick2} className={item.dropDown.length ? 'hasDropdown': undefined}>
                    <a href={item.menuItemLink}>{item.menuItemText}</a>
                    {item.dropDown &&
                      <ul className={`sub-menu ${sub ? "active" : undefined}`}> 
                        {
                          item.dropDown.map((item, index) => (
                            <li key={index}><a href={item.linkMenu}>{item.textMenu}</a></li>
                          ))
                        }
                      </ul>
                    }
                  </li>
                ))
              }
              <li className='callus'><a href="tel:0751996600" className='callAction'>0751996600</a></li>
            </ul>
            <div className={`nav-toggle ${openMenu ? "active" : undefined}`} onClick={handleClick}> 
              <span></span>
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
};

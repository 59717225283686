import './Subhero.scss';

const Subhero = ( props ) => {

    return (
      <div className="subHero">
        <div className='container'>
            {
                props?.items?.map((item, index) => (
                    <div className='item-element' key={index}>
                        <p>{item.Text}</p>
                        <img src={`${process.env.REACT_APP_BASE_URL}${item?.img?.data?.attributes?.url}`} alt="boxe depozitare de inchiriat" />
                    </div>
                ))
            }
        </div>
      </div>
    );
  };
  
  export default Subhero;
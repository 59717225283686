import * as React from 'react';
// import { Footer } from './Footer';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Outlet } from 'react-router-dom';
import { Cookie } from '../components/Cookie';
import "../styles/base.scss";

const Layout = () => (
  <main className="mainApp">
    <Header />
    <Outlet />
    <Footer />
    <Cookie />
  </main>
);

export default Layout;
import React, { useState, useEffect } from 'react';
import axios from "../api/axios";
import Hero from "../components/Hero";
import './Blog.scss';

const urlHomepage = 'articles';

const Blog = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [flag, setFlag] = useState(0);
  const [count, setCount] = useState(0);

  const getData = async (page) => {
    try {
      const response = await axios.get(urlHomepage, {
        params: {
          populate: 'deep',
          pagination: {
            page: page,
            pageSize: 3 , // Adjust the page size as per your requirement
          },
        },
      });
  
      const reversedData = response.data.data.reverse();
      setData(reversedData);
      setTotalPages(response.data.meta.pagination.total);
      setFlag(response.data.meta.pagination.pageSize);
      setCount(response.data.meta.pagination.pageCount);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const renderPaginationLinks = () => {
    const links = [];
    const prevPage = currentPage - 1;
    const nextPage = currentPage + 1;
  
    // Render previous button
    if (prevPage > 0) {
      links.push(
        <span
          key="prev"
          className="prev-page"
          onClick={() => handlePageChange(prevPage)}
        >
          Inapoi
        </span>
      );
    }
  
    // Render page numbers
    for (let page = 1; page <= count; page++) {
      links.push(
        <span
          key={page}
          className={`pagination-link ${currentPage === page ? 'active' : ''}`}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </span>
      );
    }
  
    // Render next button
    if (nextPage <= totalPages) {
      links.push(
        <span
          key="next"
          className="prev-page"
          onClick={() => handlePageChange(nextPage)}
        >
          Inainte
        </span>
      );
    }
  
    return links.length > 2 ? links : null;
  };
  
  console.log(data, 'article');

  return (
    <div className="BlogPageWrapper">
      <Hero title="Unitati personale de depozitare de inchiriat: Utilizari, Durata si Tipuri explicate." body="Ai ramas fara spatiu? O unitate personala de depozitare te poate ajuta." variation="smallHero" />
      <div className="container">
        <div className="blog_title">
          <h1>Ultimele articole</h1>
          <p className="body_articles-Page">
            Obtine cele mai recente sfaturi si trucuri despre tot ce este legat de stocare de la experti din industrie. Va vom oferi toate informatiile de care veti avea nevoie pentru a face ordine in casa dumneavoastra sau pentru a crea mai mult spatiu in locuinta dumneavoastra, cu ajutorul unor metode ingenioase.
          </p>
        </div>
        <div className="blog_listing_wrapper">
          {data?.map((item, index) => (
            <div className="item-single-article" key={index}>
              <a href={`article/${item?.attributes?.slug}`}>
                <span className="overlay">
                  <img src={`${process.env.REACT_APP_BASE_URL}${item?.attributes?.image?.data?.attributes?.url}`} alt={item?.attributes?.image?.data?.attributes?.alternativeText} />
                </span>
                <p className="date-toShow">{new Date(item.attributes.createdAt).toLocaleDateString()}</p>
                <h2>{item.attributes.title}</h2>
              </a>
            </div>
          ))}
        </div>
        {
            flag < totalPages ?
            <div className="pagination">
          {renderPaginationLinks()}
        </div> 
        : null
        }
      </div>
    </div>
  );
};

export default Blog;

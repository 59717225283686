import React, { useState, useEffect } from 'react';
import axios from "../api/axios";
import Hero from "../components/Hero";
import Form from "../components/Form";
const urlHomepage = 'contact';

const Contact = ( ) => {

  
  const [ data, setData ] = useState( [] );

  const getData = async () => {
    try {
      const response = await axios.get( urlHomepage, { params: { populate: 'deep' } } );
      setData( response.data.data );
    } catch ( err ) {
      console.error( err );
    }
  };

  useEffect( () => {
    getData();
  }, [] );

  return (
    <div className="contact">
      <Hero 
        title={data?.attributes?.contactus?.title}
        body={data?.attributes?.contactus?.body}
        cta={data?.attributes?.contactus?.cta}
        variation={data?.attributes?.small}
      />
      <Form />
    </div>
  );
};

export default Contact;

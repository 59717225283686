import './Hero.scss';

const Hero = ( props ) => {

    return (
      <div className={`heroBanner ${props.variation === true ? "smallHero" : props.variation === "boxa" ? "boxa-hero" : undefined}`}>
        {
          props.variation === false || props.variation === "boxa" ?
          <img className='backgroundBanner' src={`${process.env.REACT_APP_BASE_URL}${props?.image?.attributes?.url}`} alt="Inchirieri boxe depozitare. Inchirieri spatii de depozitare" />
          : <img className='backgroundBanner' src="/boxe.jpg" alt="Inchirieri boxe depozitare. Inchirieri spatii de depozitare" />
        }
        
        <div className='container'>
            <div className='container-wrapper'>
                <div className='text-area'>
                    <h1>{props.title}</h1>
                    <p>{props.body}</p>
                    {
                      props.variation === false ?
                      <a className='callAction' href={props.cta}>Completeaza formularul</a>
                      : undefined
                    }
                    
                </div>
                {props.variation === true ?
                  <div className='boxesHero'>
                    <div>
                      <h3>Locatie</h3>
                      <p>Strada Zarzărilor 10, București, Sector 3</p>
                    </div>
                    <div>
                      <h3>Telefon</h3>
                      <a href="tel:0751996600">Tel: 0751996600</a>
                    </div>
                    <div>
                      <h3>E-mail</h3>
                      <a href="mailto:office.idepozit@gmail.com">Mail: office.idepozit@gmail.com</a>
                    </div>
                  </div>
                : undefined
                }
            </div>
        </div>
      </div>
    );
  };
  
  export default Hero;
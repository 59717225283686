// import './App.scss';

import Layout from './components/Layout';
import { Routes, Route, useLocation } from 'react-router-dom';
import Page404 from './pages/Page404';
import HomePage from './pages/Homepage';
import Contact from './pages/Contact';
import Legal from './pages/Legal';
import Faq from './pages/Faq';
import Boxa1 from './pages/Boxa1';
import Desprenoi from './pages/Desprenoi';
import UserPage from './pages/UserPage';
import Login from './components/Login'
import Blog from './pages/Blog';
import Article from './components/Article'
import Call from './pages/Call';


const App = () => {
  const location = useLocation();
  console.warn = () => {};

  return (
    <Routes>
      <Route path="*" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="*" element={ <Page404 /> } />
      </Route>

      <Route path="/" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/" element={ <HomePage /> } />
      </Route>
      <Route path="/call" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/call" element={ <Call /> } />
      </Route>
      <Route path="/contact" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/contact" element={ <Contact /> } />
      </Route>
      <Route path="/legal" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/legal" element={ <Legal /> } />
      </Route>
      <Route path="/faq" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/faq" element={ <Faq /> } />
      </Route>
      <Route path="/boxes/:slug" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/boxes/:slug" element={ <Boxa1 /> } />
      </Route>
      <Route path="/user/:id" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/user/:id" element={ <UserPage /> } />
      </Route>
      <Route path="/despre-noi" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/despre-noi" element={ <Desprenoi /> } />
      </Route>
      <Route path="/login" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/login" element={ <Login /> } />
      </Route>
      <Route path="/login." element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/login." element={ <Login /> } />
      </Route>
      <Route path="/blog" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/blog" element={ <Blog /> } />
      </Route>
      <Route path="/article/:slug" element={ <Layout pathname={ location.pathname } /> }>
        <Route path="/article/:slug" element={ <Article /> } />
      </Route>

    </Routes>
  );
};

export default App;
